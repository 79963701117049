<template>
    <div>
        <v-card flat class="card">
            <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                <v-container>
                    <v-card outlined>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">
                                        الإسم الثلاثي
                                        <span class="required">*</span>
                                    </h3>
                                    <v-text-field
                                        v-model="item.fullName"
                                        placeholder="الإسم الثلاثي"
                                        :rules="[$global.state.required()]"
                                        required
                                        filled
                                        outlined
                                        hide-details="auto"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">
                                        رقم الهاتف
                                        <span class="required">*</span>
                                    </h3>
                                    <v-text-field
                                        v-model="item.phoneNumber"
                                        v-mask="$phoneMask"
                                        dir="ltr"
                                        placeholder="+964 ### ### ####"
                                        :rules="[$global.state.required()]"
                                        required
                                        filled
                                        outlined
                                        hide-details="auto"
                                    />
                                </v-col>
                                <!-- <v-col cols="12" sm="6">
                                    <h3 class="mb-2">
                                        كلمة السر
                                        <span class="required">*</span>
                                    </h3>
                                    <v-text-field
                                        v-model="item.password"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[$global.state.required()]"
                                        required
                                        filled
                                        outlined
                                        :type="show1 ? 'text' : 'password'"
                                        name="input-10-1"
                                        label="كلمة السر"
                                        counter
                                        @click:append="show1 = !show1"
                                    ></v-text-field>
                                </v-col> -->

                                <!-- imageUrl -->
                                <v-col cols="12" md="6">
                                    <h4 class="mb-2">صورة رئيسية</h4>
                                    <FileUpload
                                        accept="image/*;capture=camera"
                                        formData="files"
                                        endPoint="UploadFile/postUploadFiles"
                                        buttonText="رفع صورة"
                                        icon="true"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">
                                        المحافظة
                                        <span class="required">*</span>
                                    </h3>
                                    <v-autocomplete
                                        clearable
                                        v-model="item.provinceId"
                                        item-text="name"
                                        item-value="id"
                                        :items="$global.state.provinces"
                                        class="mr-1"
                                        label="المحافظة"
                                        :loading="$global.state.loading"
                                        no-data-text="لا توجد بيانات"
                                        hide-details
                                        prepend-inner-icon="place"
                                        outlined
                                        filled
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <h3 class="mb-2">أقرب نقطة دالة</h3>
                                    <v-text-field
                                        v-model="item.addres"
                                        placeholder="أقرب نقطة دالة"
                                        filled
                                        outlined
                                        hide-details="auto"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn
                                type="submit"
                                :loading="$global.state.loading"
                                x-large
                                color="primary"
                                class="btn"
                                :disabled="!valid"
                            >حفظ</v-btn>
                            <v-btn
                                @click="close()"
                                x-large
                                color="error"
                                class="mr-2 btn"
                                outlined
                            >إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import FileUpload from "@/components/FileUpload.vue";

export default {
    components: {
        FileUpload
    },
    data() {
        return {
            valid: false,
            show1: false,
            rules: {
                min: v => v.length >= 8 || "Min 8 characters"
            },
            item: {}
        };
    },

    mounted() {
        this.reset();

       
    },

    methods: {
        reset() {
            this.item = {
                fullName: "",
                phoneNumber: "",
                imageUrl: "",
                password: "",
                provinceId: "",
                addres: ""
            };
            this.$store.commit("clearFiles");
        },
        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.addItem();
            }
        },

        async addItem() {
            this.$global.state.loading = true;
            this.item.phoneNumber = this.item.phoneNumber.replace(/\s/g, "");
            this.item.expiryDate = this.expiryDate;
            if (this.$store.state.files[0] != null) {
                this.$store.state.progress = true;
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then(res => {
                        this.item.imageUrl = res.data.result.fileList[0].path;
                    });
            }
            await this.$http
                .post(`Clients`, this.item)
                .then(res => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success"
                    });
                    this.$router.push("/all-clients");
                })
                .catch(err => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error"
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },
    

        

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
</script>
